import React, { useCallback, useContext, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import app from "./base.js";
import { AuthContext } from "./Auth.js";
import axios from "axios";
import config from "./themeData/variables"

import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Contact from "./Contact";

let contactInfo = config.profilLogin;

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Identifiant incorrect
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Contact info={contactInfo} />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const Login = ({ history }) => {
  const [step, setStep] = useState(0);
  const [unAuthorizedUser, setUnAuthorizedUser] = useState(false);

  const submitContent = () => {
    if (step === 0) {
      return "Se connecter";
    }
    if (step === 1) {
      return <Spinner animation="border" as="span" size="sm" />;
    }
    if (step === 2) {
      return "Email de connexion envoyé";
    }
  };

  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email } = event.target.elements;

      setStep(1);
      setUnAuthorizedUser(false);

      let authCheck = await axios.post(
        "https://europe-west1-"+config.projectId+".cloudfunctions.net/checkAuth",
        { email: email.value },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
          }
        }
      );

      if (!authCheck.data.access) {
        setUnAuthorizedUser(true);
        setStep(0);
        return;
      }

      try {
        await app
          .auth()
          .sendSignInLinkToEmail(email.value, {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be whitelisted in the Firebase Console.
            url: window.location.origin + "/finishSignup/" + btoa(email.value),
            // This must be true.
            handleCodeInApp: true
          })
          .then(function() {
            setStep(2);
          });
        // history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { currentUser, initialized } = useContext(AuthContext);
  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div className="bodyLog">
      <div className="login">
        <img alt="Logo CEDAP" className="imgLog " src="/icon_512x512.png" />
        <MyVerticallyCenteredModal
          show={unAuthorizedUser}
          onHide={() => setUnAuthorizedUser(false)}
        />
        {initialized && (
          <form onSubmit={handleLogin} className="form-group">
            <input
              type="email"
              className="form-control mx-auto"
              placeholder="Votre email professionel"
              name="email"
              required
            />
            <button
              type="submit"
              className="btn btn-primary mx-auto"
              disabled={step !== 0}
            >
              {submitContent()}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default withRouter(Login);
