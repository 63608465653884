import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import cx from "classnames";
import ReactSVG from "react-svg";
import data from "./themeData/data.json";
import Profile from "./Profile.jsx";

import { AuthContext } from "./Auth";

import Logo from "./Logo";

import menuBurger from "./img/menu.svg";

class Header extends Component {
	static contextType = AuthContext;

	state = {
		hidden: true,
		sticky: false,
		menuOpen: false
	}

	handleScroll = (e) => {
		const { scrollY } = window;

		this.setState({
			hidden: scrollY > this._lastScrollPos && scrollY !== 0,
			sticky:  scrollY > 0
		});

		this._lastScrollPos = scrollY;
	}

	componentWillMount() {
		document.addEventListener('scroll', this.handleScroll);
		this.handleScroll();
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.handleScroll);
	}

	render() {
		const { hidden, sticky } = this.state;

		if (!this.context.currentUser) return null;

		const classes = classNames({
			Header: true,
			"Header-sticky": sticky,
			"Layout-aligned": true,
			"Header-hidden": hidden
		});

		const logoClasses = classNames({
			"Header-Logo": true,
			"Header-Logo-big": !sticky
		});

		const { menuOpen } = this.state;

		return (
			<header className={cx({ menuOpen }, classes)}>
				<Link to="/">
					<Logo className={logoClasses} baseline={true} />
				</Link>
				<nav className="Header-Menu container">
					<ul className="row col">
						{data.categories.map(({ name, id }, idx) => (
							<li className="col-md-2 col-sm-3" key={idx}>
								<Link
									to={"/content/" + id}
									onClick={() =>
										this.setState({ menuOpen: !menuOpen })
									}
								>
									{name}
								</Link>
							</li>
						))}
					</ul>
				</nav>
				<div
					className="Header-Burger Layout-aligned"
					onClick={() => this.setState({ menuOpen: !menuOpen })}
				>
					<ReactSVG src={menuBurger} />
				</div>
				<nav className="Header-Social Layout-aligned">
					<Profile />
				</nav>
			</header>
		);
	}
}

export default Header;
