import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import StateButton from "./StateButton";
import config from './themeData/variables'

import pictoCroix from "./img/png/010-close.png";
import Contact from "./Contact";

import { AuthContext } from "./Auth";

class OverlayZoom extends React.Component {
  static contextType = AuthContext;

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired
  };

  handleClick = e => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.dismiss();
    }
  };

  handleKeyPress = e => {
    const { visible } = this.props;

    if (!visible) {
      return;
    }
  };

  dismiss() {
    this.props.onDismiss();
  }

  UNSAFE_componentWillMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  UNSAFE_componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  render() {
    const {
      title,
      fournisseur,
      buttonText,
      content,
      profil,
      image,
      visible,
      onDismiss
    } = this.props;

    let { email, displayName } = this.context.currentUser;
    let payload = { fournisseur, title, email, displayName };
    let url = "https://europe-west1-"+config.projectId +".cloudfunctions.net/sendEmail";

    return (
      <div className={classNames("overlay-zoom", { "is-visible": visible })}>
        <div
          ref={wrapperRef => (this.wrapperRef = wrapperRef)}
          className="overlay-zoom-main"
        >
          <div className="overlay-zoom-content">
            <img alt="L'offre" className="img-thumbnail" src={image} />
            <div className="col">
              <h2 className="centered">{title}</h2>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>

              {buttonText ? (
                <StateButton
                  className="cta"
                  callText={buttonText}
                  url={url}
                  payload={payload}
                  doneText="Votre demande à été prise en compte!"
                />
              ) : (
                ""
              )}

              {profil ? <Contact info={profil} /> : ""}
            </div>
          </div>
          <div className="overlay-zoom-controls">
            <button className="overlay-zoom-controls-close" onClick={onDismiss}>
              <img alt="fermer" src={pictoCroix} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default OverlayZoom;
