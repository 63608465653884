import React, { useEffect, useState } from "react";
import axios from "axios";
import app from "./base.js";
import config from "./themeData/variables.json";


export const AuthContext = React.createContext();

export const AuthProvider = ({ history, children }) => {
  const initialDisplayName = () => {
    const user = app.auth().currentUser;
    if (user) {
      return user.displayName;
    }
    return "";
  };

  const [currentUser, setCurrentUser] = useState(app.auth().currentUser);
  const [initialized, setInitialized] = useState(false);
  const [displayName, setDisplayName] = useState(initialDisplayName);

  const generateDisplayName = userData => {
    let displayName = "Invité";
    if (userData) {
      displayName =
        (userData.genre || "") +
        " " +
        (userData.prenom || "") +
        " " +
        (userData.nom || "");
    }
    setDisplayName(displayName);
  };

  const updateProfile = async user => {
    try {
      let authCheck = await axios.post(
        "https://europe-west1-"+config.projectId+".cloudfunctions.net/checkAuth",
        { email: user.email },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
          }
        }
      );

      generateDisplayName(authCheck.data.userData);
      user
        .updateProfile({
          displayName: displayName
        })
        .then(function() {})
        .catch(function(error) {
          // An error happened.
        });
    } catch (error) {
      console.log("error occured when calling checkAuth");
    }
  };

  useEffect(() => {
    app.auth().onAuthStateChanged(user => {
      setCurrentUser(user);
      setInitialized(true);

      if (user) {
        updateProfile(user);
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        initialized,
        displayName
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
