import React, { useContext } from "react";
import app from "./base.js";

import { AuthContext } from "./Auth";

const Profile = () => {
  const { displayName } = useContext(AuthContext);

  return (
    <div className="bodyProfile container">
      <div className="profile">
        <p>Bienvenue {displayName}</p>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => app.auth().signOut()}
        >
          Déconnexion
        </button>
      </div>
    </div>
  );
};

export default Profile;
