import React from "react";
import { withRouter } from "react-router-dom";
import app from "./base.js";

import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

const FinishSignup = ({ history, match }) => {
  if (app.auth().isSignInWithEmailLink(window.location.href)) {
    var email = atob(match.params.id);

    app
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then(function(result) {
        console.log(result);
        history.push("/");
      })
      .catch(function(error) {
        console.log(error);
        alert(error);
        history.push("/");
      });
  } else {
    history.push("/");
  }

  return (
    <div className="bodyLog">
      <div className="login">
        <img alt="CEDAP" className="imgLog " src="/icon_512x512.png" />
        <Spinner
          animation="border"
          variant="light"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "20px"
          }}
        />
      </div>
    </div>
  );
};

export default withRouter(FinishSignup);
