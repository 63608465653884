import React from "react";
import Item from "./Item.jsx";
import data from "./themeData/data.json";

class ItemContent extends React.Component {

  render() {
    const categoryId = this.props.match.params.id;

    return (
      <div className="bodyCont">
        <div id="block-contenu" className="container">
            {
              data.categories.map(item => (
                <ul className="row justify-content-center">
                {item.fichesAccords.map(fiche => (

                    <li className="col-lg-6">
                      <Item
                        key={fiche.id}
                        itemId={item.id}
                        class_of_item='itemClass'
                        profil={fiche.profil}
                        buttonText={fiche.buttonText}
                        categoryId={categoryId}
                        image={fiche.image}
                        title={fiche.title}
                        fournisseur={fiche.fournisseur}
                        content={fiche.content}
                        website={fiche.website}
                      />
                    </li>
                ))}
                </ul>
              ))
            }

        </div>
      </div>
    );
  }
}

export default ItemContent;
