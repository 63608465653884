import React, { Fragment } from "react";
import OverlayZoom from "./OverlayZoom";
import cx from "classnames";

class Item extends React.Component {
  state = {
    overlayVisible: false
  };
  render() {
    const { overlayVisible } = this.state;
    const {
      itemId,
      class_of_item,
      categoryId,
      title,
      fournisseur,
      content,
      profil,
      buttonText,
      image,
      website
    } = this.props;
    return (
      <Fragment>
        <div
          className={cx(class_of_item, itemId === categoryId ? {"item-active": true} : {})}
          onClick={() => this.setState({ overlayVisible: true })}
        >
          <div>
            <img alt="fiche accords" src={image} />
            <p>{title}</p>
          </div>
        </div>
        <OverlayZoom
          image={image}
          title={title}
          fournisseur={fournisseur}
          profil={profil}
          buttonText={buttonText}
          content={content}
          website={website}
          visible={overlayVisible}
          onDismiss={() => this.setState({ overlayVisible: false })}
        />
      </Fragment>
    );
  }
}

export default Item;
