import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

function initialize() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        return firebase.initializeApp({
            apiKey: process.env.REACT_APP_FIREBASE_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        });
    } else {
        var request = new XMLHttpRequest();
        request.open("GET", "/__/firebase/init.json", false); // `false` makes the request synchronous
        request.send(null);
        console.log(request);
        return firebase.initializeApp(JSON.parse(request.responseText));
        // production code
    }
}

const app = initialize();
firebase.analytics();

export default app;
