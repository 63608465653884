import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";

const StateButton = ({ callText, doneText, url, payload }) => {
	const [state, setState] = useState(0);
	const [variant, setVariant] = useState("primary");

	const handleClick = () => {
		setState(1);
	};

	const networkRequest = async () => {
		try {
			await axios.post(url, payload, {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*"
				}
			});
			setState(2);
			setVariant("success");
		} catch (error) {
			console.log(error);
			setState(0);
			alert(
				"Une erreur est survenue. Merci de ré-essayer ultérieurement"
			);
		}
	};

	const onClick = () => {
		if (state === 0) {
			return handleClick;
		}
		return null;
	};

	useEffect(() => {
		if (state === 1) {
			networkRequest();
		}
	}, [state]);

	const content = () => {
		if (state === 0) {
			return callText;
		}
		if (state === 1) {
			return "Demande en cours...";
		}
		if (state === 2) {
			return doneText;
		}
	};

	return (
		<Button
			variant={variant}
			size="lg"
			className="cta btn btn-primary"
			block
			disabled={state !== 0}
			onClick={onClick()}
		>
			{content()}
		</Button>
	);
};

export default StateButton;
