import React from 'react';
import cx from 'classnames';

import image from './themeData/images/logo.png';

const Logo = ({ className, baseline, color, size }) => {
	const classes = cx({
		'Logo': true,
		'Logo-baselineDisabled': !baseline
	});

	return (
		<img src={image} className={cx(className, classes)} />
	);
};

// Logo.propTypes = {
// 	baseline: PropTypes.boolean
// };

Logo.defaultProps = {
	baseline: false
};

export default Logo;
