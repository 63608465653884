import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ItemContent from "./ItemContent.jsx";
import Login from "./Login.js";
import FinishSignup from "./finishSignup.jsx";
import Home from "./Home";
import Header from "./Header.jsx";
import PrivateRoute from "./PrivateRoute.js";
import { AuthProvider } from "./Auth.js";

import "./css/main.scss";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute path="/content/:id" component={ItemContent} />
        <Route exact path="/login" component={Login} />
        <Route path="/finishSignup/:id" component={FinishSignup} />
      </Router>
    </AuthProvider>
  );
};

export default App;
