import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";

const Contact = ({ info }) => {
	return (
		<Container className="contact-content">
			<div className="header">
				Une question? Votre contact privilégié vous répond!
			</div>
			<div className="body">
				<Row>
					<Col xs={12} sm={4}>
						<Image src="/avatars/justine.png" />
					</Col>
					<Col className="infoCol">
						<div className="title">
							<h4>
								{info.prenom} {info.nom}
							</h4>
						</div>
						<div className="presentation">
							<h5>{info.presentation}</h5>
						</div>
						<div className="telephone">
							<a href={"tel://" + info.telephone}>
								{info.telephone}
							</a>
						</div>

						<div className="mail">
							<a
								href={"mailto:" + info.email}
								target="_blank"
								rel="noopener noreferrer"
							>
								Contacter par email
							</a>
						</div>
					</Col>
				</Row>
			</div>
		</Container>
	);
};

export default Contact;
